/*
* 업무구분 : 고객
* 화면 명  : SearchDetail
* 화면 설명: 상세조회 컴포넌트
* 설명    :  - init 함수: 조직 조회(사업부->지역단->지점->컨설턴트 순으로 조회)
*            - 컨설턴트 select box (props: isShowCnslt === true 경우 노출)
*            - 조직 변경시, 하위 선택 조직 및 컨설턴트 재조회 및 초기화 => emit
*            - 초기화: 조직/컨설턴트 사용자 조직 정보로 초기화 (부모화면에서 pOrgData 변경시, watch=>fn_ClearOrg 실행)
*            - 사용자 정보 - 지점과 지역단이 같은 경우 존재
*            - 지점 선택값이 전체 경우, 담당 컨설턴트 리스트 = 해당 조직의 임직원 리스트
*/
<template>
  <ur-box-container alignV="start" componentid="" direction="column" class="ns-sch-area pal0">
    <ur-box-container alignV="start" componentid="" direction="column" class="info-box pb20 pal0">
      <div class="info-title-wrap mb0">
        <span class="info-title fs14rem fwn crTy-bk7">조직</span>
      </div>
      <ur-box-container alignV="start" componentid="" direction="column" class="">
        <msp-bottom-select  class="ns-dropdown-sheet mb20 mt0" placeholder="사업부" bottom-title="사업부" closeBtn
        :itemHeight="374" v-model="lv_HofOrg.key" :itemValue="'key'" :itemText="'label'"
        :items="lv_HofOrgData" @input="fn_GetDofData(lv_HofOrg)" :disabled="lv_OrgAuth.isEntpwRolYn==='N'" underline/>
      <msp-bottom-select  class="ns-dropdown-sheet mb20 mt0" placeholder="지역단" bottom-title="지역단" closeBtn
        :itemHeight="374" v-model="lv_DofOrg.key" :itemValue="'key'" :itemText="'label'"
        :items="lv_DofOrgData" @input="fn_GetFofData(lv_DofOrg)" :disabled="lv_OrgAuth.isHofRolYn==='N'" underline/>
      <msp-bottom-select class="ns-dropdown-sheet mt0" placeholder="지점" bottom-title="지점" closeBtn
        :itemHeight="374" v-model="lv_FofOrg.key" :itemValue="'key'" :itemText="'label'"
        :items="lv_FofOrgData" @input="fn_GetFofCnsltData(lv_FofOrg)" :disabled="lv_OrgAuth.isDofRolYn==='N'" underline/>
      </ur-box-container>
    </ur-box-container>
    <ur-box-container alignV="start" componentid="" direction="column" class="info-box pb20 pal0" v-if="isShowCnslt">
      <div class="info-title-wrap">
        <span class="info-title fs14rem fwn crTy-bk7">담당 컨설턴트</span>
      </div>
      <ur-box-container alignV="start" componentid="" direction="column">
        <msp-bottom-select class="ns-dropdown-sheet" placeholder="담당컨설턴트" bottom-title="담당컨설턴트" closeBtn
          :itemHeight="374" v-model="lv_FofCnslt.key" :itemValue="'key'" :itemText="'label'"
          :items="lv_FofCnsltData" @input="fn_SetCondOrg(lv_FofCnslt)" :disabled="lv_OrgAuth.isEofRolYn==='N'" underline/>
      </ur-box-container>
    </ur-box-container>
  </ur-box-container>
</template>

<script>
/***********************************************************************************
* 공통 라이브러리 INCLUDE 영역
***********************************************************************************/
import MspBottomSelect from '@/ui/_cmm/components/MspBottomSelect.vue'

export default{
  /***********************************************************************************
  * Vue 파일 속성 정의 영역
  ***********************************************************************************/

  name: 'SearchCondOrg',
  screenId: 'SearchCondOrgAP',
  components: {MspBottomSelect},
  props: {
    'isShowCnslt': {
      type: Boolean,
      default: true
    },
    'pOrgData': {
      type: Object,
      default: {},
      name: '최근 검색조건'
    }
  },

  /***********************************************************************************
  * 화면 UI Data Field 정의
  ***********************************************************************************/
  data () {
    return {
      lv_UserInfo: {},
      lv_SearchParam: { whlInqrYn: 'Y', hofOrgNo: '', dofOrgNo: '', fofOrgNo: '', orgNo: '', cnsltInqrYn: '' }, // 검색조건

      lv_HofOrg: {}, // 선택사업부
      lv_DofOrg: {}, // 선택지역단
      lv_FofOrg: {}, // 선택지점
      lv_FofCnslt: {}, // 선택컨설턴트

      lv_DefaultObj: [
        {key: '00000000', label: '선택'},
        {key: '0', label: '선택'}
      ],
      lv_SelectOrg: '', // 선택한 조직

      lv_UserHofOrg: {}, // 사용자 사업부
      lv_UserDofOrg: {}, // 사용자 지역단
      lv_UserFofOrg: {}, // 사용자 지점

      lv_OrgData: {}, // 조직 orignal 데이터
      lv_pOrgData: {}, // 최근 검색조건 (조직/컨설턴트)

      lv_HofOrgData: [], // 사업부 목록
      lv_DofOrgData: [], // 지역단 목록
      lv_FofOrgData: [], // 지점 목록
      lv_FofCnsltData: [], // 컨설턴트 목록

      lv_OrgAuth: [], // 지점권한
      lv_IsParam: false, // 조직 파라미터가 존재 여부
      lv_ReturnVal: {} // emit 변수
    }
  },
  /***********************************************************************************
  * Life Cycle 함수 정의 영역
  ***********************************************************************************/
  mounted () {
    this.fn_Init()
  },
  /***********************************************************************************
  * watch 정의 영역
  ***********************************************************************************/
  watch: {
    pOrgData() {
      // 조직 컴포넌트 초기화 요청시
      this.fn_ClearOrg()
    }
  },
  /***********************************************************************************
  * 사용자 함수 정의 영역
  ***********************************************************************************/
  methods: {
    /******************************************************************************
    * @function fn_Init
    * @notive   컴포넌트 호출시 초기 실행 함수
    *             1. 사용자의 조직조회 권한 확인
    *             2. 시스템 옵션 조회(fn_GetFofRolCallBack 함수)
    ******************************************************************************/
    fn_Init() {
      this.lv_UserInfo = this.getStore('userInfo').getters.getUserInfo
      console.log('userInfo> ', this.lv_UserInfo)
      this.lv_UserHofOrg = {key: this.lv_UserInfo.onpstHofOrgNo, label: this.lv_UserInfo.onpstHofOrgNm}
      this.lv_UserDofOrg = {key: this.lv_UserInfo.onpstDofNo, label: this.lv_UserInfo.onpstHofOrgNm}
      this.lv_UserFofOrg = {key: this.lv_UserInfo.onpstFofOrgNo, label: this.lv_UserInfo.onpstHofOrgNm}

      // 최근 검색조건
      this.lv_pOrgData = this.pOrgData
      
      this.fn_GetFofRol()
    },
    /******************************************************************************
    * @function fn_GetFofRol
    * @notive   사용자의 조직조회 권한 확인
    * @callbackFunc fn_GetFofRolCallBack
    ******************************************************************************/
    fn_GetFofRol () {
      this.$bizUtil.selFofRol({}, this.fn_GetFofRolCallBack )
    },
    /******************************************************************************
    * @function fn_GetFofRolCallBack
    * @notice   조직조회권한 셋팅 (@this lv_OrgAuth)
    *            { isEntpwRolYn[전사역할여부]=Y/N,
    *              isHofRolYn[사업부역할여부]=Y/N,
    *              isDofRolYn[지역단역할여부]=Y/N,
    *              isEofRolYn[지점역할여부]=Y/N   }
    ******************************************************************************/
    fn_GetFofRolCallBack ( fofRolData ) {
      if ( fofRolData ) {
        this.lv_OrgAuth = fofRolData

        if (fofRolData.isHofRolYn === 'N' && fofRolData.isDofRolYn === 'N'
            && fofRolData.isEofRolYn === 'N' && fofRolData.isEntpwRolYn === 'N') return

        if (!_.isEmpty(this.pOrgData)){ // 최근 조회 조직
          if (!_.isEmpty(this.pOrgData.hofOrg)&& !_.isEmpty(this.pOrgData.dofOrg) )
            this.lv_SearchParam.hofOrgNo = this.pOrgData.hofOrg.key
            this.lv_SearchParam.dofOrgNo = this.pOrgData.dofOrg.key
        } else { // 사용자 조직
          this.lv_SearchParam.hofOrgNo = this.lv_UserInfo.onpstHofOrgNo
          this.lv_SearchParam.dofOrgNo = this.lv_UserInfo.onpstDofNo
        }

        this.$bizUtil.selUserOrgList(this.lv_SearchParam, this.fn_OrgSearchCallBack)
      }
    },
    /******************************************************************************
    * @function fn_OrgSearchCallBack
    * @notice   조직정보 조회 콜백 함수
    *           @param rtnData: (조직/컨설턴트)데이터
    *           @param sType: 조회데이터 타입 ('CNSLT', 'FOF')
    ******************************************************************************/
    fn_OrgSearchCallBack ( rtnData, sType ) {
      console.log(sType, rtnData)
      if (sType === 'CNSLT' && this.isShowCnslt) {
        this.fn_SetCnsltSearch(rtnData)
        if (_.isEmpty(this.lv_FofCnslt)) {
          this.lv_FofCnslt.key = '0'
          this.lv_FofCnslt.label = '전체'
        }
        // 사용자 정보 - 
        // 지점 === 지역단 경우, 지점을 전체로 변경
        if(_.isEmpty(this.lv_pOrgData)) {
          if (this.lv_DofOrg.key === this.lv_FofOrg.key && 
                      this.lv_DofOrg.label === this.lv_FofOrg.label) {
            this.lv_FofOrg = {key: '0', label: '전체'}
          }
        } else {
          this.lv_FofCnslt.key = this.lv_pOrgData.cnslt.key
          this.lv_FofCnslt.label = this.lv_pOrgData.cnslt.label
        }
      } else {
        // 조직 셋팅
        this.fn_SetOrgSearch(rtnData)
        
        if (this.isShowCnslt) {
          // 컨설턴트 조회
          if (this.lv_SelectOrg === '' || this.lv_SelectOrg === 'FOF') {
            this.fn_SearchFofCnslt()
          } else { // 컨설턴트 디폴트
            this.lv_FofCnsltData = [{key: '0', label: '전체'}]
          }
        }
      }
      this.fn_SetReturnVal()
      this.lv_SelectOrg = ''
    },
    /******************************************************************************
    * @function fn_SetOrgSearch
    * @notice   조직정보 셋팅
    * @param sOrgData: 조회해서 받아온 조직 데이터
    ******************************************************************************/
    fn_SetOrgSearch(sOrgData) {
      if (_.isEmpty(sOrgData)) return
  
      // Orignal Data 
      this.lv_OrgData.hofOrgData = this.$bizUtil.cloneDeep(sOrgData[0])
      this.lv_OrgData.dofOrgData = this.$bizUtil.cloneDeep(sOrgData[1])
      this.lv_OrgData.fofOrgData = this.$bizUtil.cloneDeep(sOrgData[2])

      // Rendering Data
      this.lv_HofOrgData = [...sOrgData[0]]
      this.lv_DofOrgData = [...sOrgData[1]]
      this.lv_FofOrgData = [...sOrgData[2]]

      if (this.lv_SelectOrg === '') {
        if (!_.isEmpty(this.pOrgData)) {
          // 최근 조회 조직으로 셋팅
          this.lv_HofOrg = this.lv_OrgData.hofOrgData.filter(item => {
            return item.key === this.pOrgData.hofOrg.key
          })[0]
          this.lv_DofOrg = this.lv_OrgData.dofOrgData.filter(item => {
            return item.key === this.pOrgData.dofOrg.key
          })[0]
          this.lv_FofOrg = this.lv_OrgData.fofOrgData.filter(item => {
            return item.key === this.pOrgData.fofOrg.key
          })[0]

        } else {
          // 사용자 조직정보으로 셋팅
          this.lv_HofOrg = this.lv_OrgData.hofOrgData.filter(item => {
            return item.key === this.lv_UserInfo.onpstHofOrgNo
          })[0]
          this.lv_DofOrg = this.lv_OrgData.dofOrgData.filter(item => {
            return item.key === this.lv_UserInfo.onpstDofNo
          })[0]
          this.lv_FofOrg = this.lv_OrgData.fofOrgData.filter(item => {
            return item.key === this.lv_UserInfo.onpstFofOrgNo
          })[0]

          if(_.isEmpty(this.lv_DofOrg)) {
            this.lv_DofOrg = {key: '0', label: '전체'}
          }
          if(_.isEmpty(this.lv_FofOrg)) {
            this.lv_FofOrg = {key: '0', label: '전체'}
          }
        }
      } else {
        switch (this.lv_SelectOrg) {
          case 'HOF':
            this.lv_DofOrg = {key: '0', label: '전체'}
          case 'DOF':
            this.lv_FofOrg = {key: '0', label: '전체'}
          case 'FOF':
            this.lv_FofCnslt = {key: '0', label: '전체'}
            break;
        }
      }
    },
    /******************************************************************************
    * @function fn_SetCnsltSearch
    * @notice   컨설턴트 정보 셋팅
    * @param sOrgData: 조회해서 받아온 컨설턴트 데이터
    ******************************************************************************/
    fn_SetCnsltSearch(sCnsltData) {
      if (_.isEmpty(sCnsltData)) return

      // Orignal Data 
      this.lv_OrgData.fofCnsltData = this.$bizUtil.cloneDeep(sCnsltData)

      // Rendering Data
      this.lv_FofCnsltData = [...sCnsltData]

      if (this.lv_SelectOrg === '') {
        if (!_.isEmpty(this.pOrgData)) {
          // 최근 조회 컨설턴트로 셋팅
          let fofCnslt = this.lv_FofCnsltData.filter(item => {
            return item.key === this.pOrgData.cnslt.key
          })[0]
          
          if ( this.pOrgData.fofOrg.key === '0' ) {
            this.lv_FofOrg = {key: '0', label: '전체'}
          } else {
            this.lv_FofCnslt = {key: fofCnslt.key, label: fofCnslt.label}
          }
        } else {
          // 사용자 정보으로 셋팅
          let fofCnslt = this.lv_FofCnsltData.filter(item => {
            return item.key === this.lv_UserInfo.cnsltNo
          })[0]

          if (_.isEmpty(fofCnslt)) {
            this.lv_FofCnslt.key = '0'
            this.lv_FofCnslt.label = '전체'
          } else {
            this.lv_FofCnslt = {key: fofCnslt.key, label: fofCnslt.label}
          }
        }
      } else {
        this.lv_FofCnslt.key = '0'
        this.lv_FofCnslt.label = '전체'
      }
    },

    /***********************************************************************************
    * 조직/컨설턴트 변경 Event
    ***********************************************************************************/

    /******************************************************************************
    * @function fn_GetDofData
    * @notice   사업부 변경 Event
    * @param selHofData: 선택한 사업부 Object
    ******************************************************************************/
    fn_GetDofData(selHofData) {
      console.log('사업부 변경...☞')
      this.lv_pOrgData = {} // 초기화
      this.lv_SelectOrg = 'HOF'

      let hofOrg = this.lv_HofOrgData.filter(item => {
        return item.key === selHofData.key
      })[0]

      this.lv_HofOrg = {key: hofOrg.key, label: hofOrg.label}

      console.log('변경 >>', this.lv_HofOrg)

      // 지역단 조회
      this.fn_SearchDof(this.lv_HofOrg)
    },
    /******************************************************************************
    * @function fn_GetFofData
    * @notice   지역단 변경 Event
    * @param selHofData: 선택한 지역단 Object
    ******************************************************************************/
    fn_GetFofData(selDofData) {
      console.log('지역단 변경...☞')
      this.lv_pOrgData = {} // 초기화
      this.lv_SelectOrg = 'DOF'

      let dofOrg = this.lv_DofOrgData.filter(item => {
        return item.key === selDofData.key
      })[0]

      this.lv_DofOrg = {key: dofOrg.key, label: dofOrg.label}

      console.log('변경 >>', this.lv_DofOrg)

      // 지역단 전체 선택시, 지점/컨설턴트 DefultObject로 셋팅
      if (!_.isEmpty(selDofData) && selDofData.key === '0') {
        this.lv_FofOrgData = [{key: '0', label: '전체'}]
        this.lv_FofOrg = {key: '0', label: '전체'}
        this.lv_FofCnsltData = [{key: '0', label: '전체'}]
        this.lv_FofCnslt = {key: '0', label: '전체'}
      } else {
        // 지점 조회
        this.fn_SearchFof(this.lv_DofOrg)
      }
    },
    /******************************************************************************
    * @function fn_GetFofCnsltData
    * @notice   지점 변경 Event
    * @param selHofData: 선택한 지점 Object
    ******************************************************************************/
    fn_GetFofCnsltData(selFofData) {
      console.log('지점 변경...☞')
      this.lv_pOrgData = {} // 초기화
      this.lv_SelectOrg = 'FOF'

      let fofOrg = this.lv_FofOrgData.filter(item => {
          return item.key === selFofData.key
      })[0]
      
      this.lv_FofOrg = {key: fofOrg.key, label: fofOrg.label}

      console.log('변경 >>', this.lv_FofOrg)

        if (this.isShowCnslt) {
          // 컨설턴트 조회
          this.fn_SearchFofCnslt(this.lv_FofOrg)
        } else {
          this.fn_SetReturnVal()
        }
    },
    /******************************************************************************
    * @function fn_SetCondOrg
    * @notice   컨설턴트 변경 Event
    * @param selFofCnslt: 선택한 컨설턴트 Object
    ******************************************************************************/
    fn_SetCondOrg(selFofCnslt) {
      console.log('컨설턴트 변경...☞')
      this.lv_pOrgData = {} // 초기화
      this.lv_SelectOrg = 'CNSLT'

      let fofCnslt = this.lv_OrgData.fofCnsltData.filter(item => {
        return item.key === selFofCnslt.key
      })[0]

      this.lv_FofCnslt = {key: fofCnslt.key, label: fofCnslt.label}

      this.fn_SetReturnVal()
    },

    /***********************************************************************************
    * 조직/컨설턴트 조회 Function
    ***********************************************************************************/

    /******************************************************************************
    * @function fn_SearchDof
    * @notice   지역단 조회
    * @param hofOrg: 선택한 지역단 Object
    ******************************************************************************/
    fn_SearchDof(hofOrg) {
      
      if (_.isEmpty(hofOrg)) {
        this.lv_SearchParam.hofOrgNo = this.lv_HofOrg.key
      } else {
        this.lv_SearchParam.hofOrgNo = hofOrg.key
      }

      // 지역단 조회
      this.$bizUtil.selListDofOrg(this.lv_SearchParam, this.fn_OrgSearchCallBack)
    },
    /******************************************************************************
    * @function fn_SearchFof
    * @notice   지점 조회
    * @param dofOrg: 선택한 지점 Object
    ******************************************************************************/
    fn_SearchFof(dofOrg) {

      if (_.isEmpty(dofOrg)) {
        this.lv_SearchParam.dofOrgNo = this.lv_DofOrg.key
      } else {
        this.lv_SearchParam.dofOrgNo = dofOrg.key
      }
      
      this.$bizUtil.selListFofOrg(this.lv_SearchParam, this.fn_OrgSearchCallBack)
    },
    /******************************************************************************
    * @function fn_SearchFofCnslt
    * @notice   컨설턴트 조회
    * @param fofOrg: 선택한 컨설턴트 Object
    ******************************************************************************/
    fn_SearchFofCnslt(fofOrg) {
      if (!this.isShowCnslt) return

      let chkDofOrg = !_.isEmpty(this.lv_DofOrg) && this.lv_DofOrg.key !== '0'

      // 최근 조회 조직이 존재시
      if (!_.isEmpty(this.lv_pOrgData)) {
        this.lv_SearchParam.dofOrgNo = this.lv_pOrgData.dofOrg.key

        if (this.lv_pOrgData.fofOrg.key === '0') {
          this.lv_SearchParam.fofOrgNo = chkDofOrg ? this.lv_DofOrg.key : this.lv_UserDofOrg.key
        } else {
          this.lv_SearchParam.fofOrgNo = this.lv_pOrgData.fofOrg.key
        }
      } else {
        // 최근 조회 조직이 없을시
        if(_.isEmpty(fofOrg)) {
          this.lv_DofOrg = this.lv_DofOrg ? this.lv_DofOrg : {key: '0', label: '전체'}
          this.lv_FofOrg = this.lv_FofOrg ? this.lv_FofOrg : {key: '0', label: '전체'}
  
          this.lv_SearchParam.dofOrgNo = chkDofOrg ? this.lv_DofOrg.key : this.lv_UserDofOrg.key
          this.lv_SearchParam.fofOrgNo = this.lv_UserFofOrg.key
        } else {
          this.lv_SearchParam.dofOrgNo = chkDofOrg ? this.lv_DofOrg.key : this.lv_UserDofOrg.key
  
          if (fofOrg.key === '0') {
            this.lv_SearchParam.fofOrgNo = chkDofOrg ? this.lv_DofOrg.key : this.lv_UserDofOrg.key
          } else {
            this.lv_SearchParam.fofOrgNo = fofOrg.key
          }
        }
      }


      this.$bizUtil.selListFofCnslt(this.lv_SearchParam, this.fn_OrgSearchCallBack)
    },

    /******************************************************************************
    * @function fn_SetReturnVal
    * @notice   선택 조직/컨설턴트 전달
    * @return lv_ReturnVal:{사업부/지역단/지점/컨설턴트}
    ******************************************************************************/
    fn_SetReturnVal () {
      this.lv_ReturnVal = {
        hofOrg: this.lv_HofOrg,
        dofOrg: this.lv_DofOrg,
        fofOrg: this.lv_FofOrg
      }
      if (this.isShowCnslt) {
        this.lv_ReturnVal.cnslt = this.lv_FofCnslt
      }
      
      console.log('selectResult', JSON.parse(JSON.stringify(this.lv_ReturnVal)))
      this.$emit('selectResult', JSON.parse(JSON.stringify(this.lv_ReturnVal)))
    },
    /******************************************************************************
    * @function fn_ClearOrg
    * @notice   초기화(사용자 정보로 셋팅)
    *            lv_pOrgData: 최근 조회 조직
    ******************************************************************************/
    fn_ClearOrg() {
      console.log('초기화...☞')
      // 변수 초기화
      this.lv_pOrgData = {}
      this.lv_SelectOrg = ''

      // 사용자 정보로 셋팅
      this.fn_Init()
    },
  } // ::Methods End
}
</script>
